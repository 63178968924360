<template>
  <div class="temporaryParkCostRuleList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增规则" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="计费规则名称" v-model="searchParams.ruleName"></v-input>
        <v-select label="规则状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { statusOps, statusMap } from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'TemporaryParkCostRuleList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps,
      communityParams,
      searchParams: {
        ruleName: '',
        status: undefined,
        communityId: ''
      },
      headers: [
        {
          prop: 'ruleName',
          label: '计费规则名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'normalPrice',
          label: '正常时段价格(元/时)',
          align: 'right'
        },
        {
          prop: 'maxAmount',
          label: '24小时内正常时段封顶价(元)',
          align: 'right'
        },
        {
          prop: 'freeMinute',
          label: '免费时长(分钟)',
          align: 'right'
        },
        {
          prop: 'statusText',
          label: '规则状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'temporaryParkCostRuleForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'temporaryParkCostRuleForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
